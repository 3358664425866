<template>
    <div class="docs-page">
        <BaseHeader />
        <div v-if="data.length" class="container content-wrapper">
            <Sidebar :data="data" :content="content" @toggle-sidebar="toggleSidebar" @change-content="changeContent" />

            <div class="content">
                <div id="content-html" class="content-html" v-html="markdownToHtml" />
                <DocsNavigation :data="data" :content="content" @change-content="changeContent" />
            </div>
        </div>
    </div>
</template>

<script>
import { marked } from 'marked';

import BaseHeader from '@/components/Layouts/BaseHeader';
import Sidebar from '@/components/Docs/Sidebar';
import DocsNavigation from '@/components/Docs/DocsNavigation';

export default {
    name: 'Docs',
    components: {
        DocsNavigation,
        BaseHeader,
        Sidebar,
    },
    metaInfo () {
        return {
            title: this.pageTitle,
            meta: [
                { property: 'og:title', content: this.pageTitle },
            ]
        };
    },
    data () {
        return {
            data: [],
            content: {
                category: 0,
                content: 0
            },
            toggleSidebar: false,
            pageTitle: this.$t('docsTitle'),
        };
    },
    computed: {
        markdownToHtml () {
            return marked(this.data[this.content.category].children[this.content.content].content);
        }
    },
    watch: {
        '$i18n.locale' () {
            this.loadDataByLocale();
        }
    },
    created () {
        this.loadDataByLocale();
        this.changeContentFromUrl();
    },
    methods: {
        changeContent (payload) {
            const { category, content, link } = payload;
            this.content = {
                category: category,
                content: content
            };

            const id = !!link ?
                link
                    .toLowerCase()
                    .replaceAll(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, '')
                    .replaceAll(' ', '-')
                : 'content-html';
            this.$nextTick(() => {
                const element = document.getElementById(id);

                if (!!element) {
                    element.scrollIntoView({
                        block: 'start',
                        behavior: 'smooth'
                    });
                }
            });

            const categoryUrl = this.data[category].url;
            const contentUrl = this.data[category].children[content].url;
            const path = `/docs/${categoryUrl}/${contentUrl}`;

            if (path !== this.$route.path) {
                this.$router.push({ path: path });
            }
        },
        loadDataByLocale () {
            const { data } = require(`@/data/documents/${this.$i18n.locale}/index.js`);
            this.data = data;

            // Генерация путей документации для sitemap.xml
            // data.forEach(item => {
            //     item.children.forEach(child => console.log(`https://slide-lab.ru/docs/${item.url}/${child.url}`));
            // });
        },
        changeContentFromUrl () {
            const urlArr = this.$route.path.split('/').filter(item => !!item);

            if (urlArr.length > 2) {
                const category = urlArr[1];
                const content = urlArr[2];

                const categoryIndex = this.data.findIndex(item => item.url === category);
                if (categoryIndex > -1) {
                    const contentIndex = this.data[categoryIndex].children.findIndex(item => item.url === content);

                    if (contentIndex > -1) {
                        this.changeContent({ category: categoryIndex, content: contentIndex });
                    }
                }
            }
        }
    },
};
</script>

<style lang="scss">
.docs-page {
    padding-top: 56px;

    .content-wrapper {
        display: flex;

        .content {
            flex-grow: 1;
            display: flex;
            padding-top: 20px;
            overflow-x: scroll;
            align-items: center;
            flex-direction: column;
            height: calc(100vh - 56px);

            .content-html {
                width: 100%;
                color: #2c3e50;
                font-size: 16px;
                max-width: 740px;
                margin: 0 auto 35px;
                font-family: Montserrat;
                padding: 0 25px 0 2.5rem;

                h1, h2 {
                    margin-top: 0;
                    padding-top: 24px;
                }

                img {
                    height: auto;
                    max-width: 100%;
                }

                a {
                    color: #82568e;
                    text-decoration: none;

                    &:hover {
                        text-decoration: underline;
                    }
                }

                video {
                    max-width: 100%;
                }

                code {
                    color: #476582;
                    padding: 0.25rem 0.5rem;
                    margin: 0;
                    font-size: .85em;
                    background-color: rgba(27, 31, 35, .05);
                    border-radius: 3px;
                }

                ul, ol {
                    padding-left: 18px;
                    margin: 16px 0;

                    p {
                        margin: 0;
                        padding: 0;
                    }
                }

                & > p {
                    margin: 16px 0;
                }

                ol, p, ul {
                    line-height: 1.7;
                }
            }
        }
    }
}
</style>
