<template>
    <aside class="sidebar">
        <SearchBox :data="data" :content="content" @change-content="p => changeContent(p.category, p.content, p.link)"/>
        <div v-for="(item, categoryIndex) in data" :key="categoryIndex" class="categories">
            <p class="category-title" @click="changeContent(categoryIndex, 0)">
                {{ item.title }}
            </p>
            <ul class="content-list" v-if="categoryIndex === content.category">
                <li
                    v-for="(child, childIndex) in item.children"
                    :key="childIndex"
                    @click.stop="changeContent(categoryIndex, childIndex)"
                    class="content-link"
                >
                    <div class="child-title" :class="{active: childIndex === content.content}">
                        {{ child.title }}
                    </div>

                    <ul class="content-list" v-if="categoryIndex === content.category">
                        <li
                            v-for="(link, linkIndex) in child.children"
                            :key="linkIndex"
                            @click.stop="changeContent(categoryIndex, childIndex, link.title)"
                            class="content-link"
                        >
                            {{ link.title }}
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </aside>
</template>

<script>
/* eslint-disable */
import SearchBox from '@/components/Docs/SearchBox/SearchBox';

export default {
    name: 'Sidebar',
    props: {
        data: Array,
        content: Object,
    },
    components: {
        SearchBox
    },
    data() {
        return {
            toggleSidebar: false,
        }
    },
    methods: {
        changeContent(category, content, link = null) {
            this.$emit('change-content', { category, content, link })
        }
    }
}
</script>

<style scoped lang="scss">
.sidebar {
    width: 320px;
    padding-top: 20px;
    overflow-x: scroll;
    height: calc(100vh - 56px);
    border-right: 1px solid #eaecef;
    user-select: none;

    .categories {
        margin-bottom: 15px;

        .category-title {
            cursor: pointer;
            color: #2c3e50;
            transition: color .15s ease;
            font-size: 1.1em;
            font-weight: 700;
            padding: 0.35rem 1.5rem 0.35rem .25rem;
            width: 100%;
            box-sizing: border-box;
            margin: 0;
            border-left: 0.25rem solid transparent;

            &:hover {
                color: #82568e;
            }
        }

        .content-list {
            padding: 0;
            margin: 10px 0 0 0;
            list-style-type: none;

            .content-link {
                cursor: pointer;
                font-size: 1em;
                font-weight: 400;
                display: inline-block;
                color: #2c3e50;
                border-left: 0.25rem solid transparent;
                padding: 0.35rem 1rem 0.35rem 1.25rem;
                line-height: 1.4;
                width: 100%;
                box-sizing: border-box;

                .child-title {
                    font-size: 1rem;
                    font-weight: 600;
                }

                .active {
                    color: #82568e;
                    position: relative;

                    &::before {
                        content: '';
                        left: -20px;
                        height: 100%;
                        position: absolute;
                        border-left: 4px solid #82568e;
                    }
                }
            }
        }
    }
}


// -------------------
//@media (max-width: 777px) {
//  .sidebar {
//    display: block;
//  }
//}

</style>
