<template>
  <div class="search-box">
    <input
        ref="input"
        aria-label="Search"
        :model="query"
        :class="{ 'focused': focused }"
        :placeholder="$t(placeholder)"
        autocomplete="off"
        spellcheck="false"
        @input="query = $event.target.value"
        @focus="focused = true"
        @blur="focused = false"
        @keyup.enter="go(focusIndex)"
        @keydown.up="onUp"
        @keydown.down="onDown"
    >
    <ul
        v-if="showSuggestions"
        class="suggestions"
        :class="{ 'align-right': alignRight }"
        @mouseleave="unfocus"
    >
      <li
          v-for="(s, i) in suggestions"
          :key="i"
          class="suggestion"
          :class="{ focused: i === focusIndex }"
          @mousedown="go(i)"
          @mouseenter="focus(i)"
      >
        <a>
          <span class="page-title">{{ s.parentTitle }}</span>
          <span v-if="s.parentTitle" class="page-part"> → {{ s.title }}</span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
/* eslint-disable */
export default {
  name: 'SearchBox',
  props: {
    data: Array,
    content: Object,
    placeholder: {
      type: String,
      default: 'defaultPlaceholder'
    }
  },
  data() {
    return {
      query: '',
      focusIndex: 0,
      buttonKeys: [],
      focused: false,
      searchMaxSuggestions: 10,
    }
  },
  computed: {
    showSuggestions() {
      return (
          this.focused
          && this.suggestions
          && this.suggestions.length
      )
    },
    suggestions() {
      const query = this.query.trim().toLowerCase()
      if (!query) {
        return [];
      }

      const res = [];
      const data = this.data
      const max = this.searchMaxSuggestions;

      for (let categoryIndex = 0; categoryIndex < data.length; categoryIndex++) {
        if (res.length >= max) {
          break;
        }

        const category = data[categoryIndex]
        let categorySuggestion = null;

        if (category.title.toLowerCase().search(query) != -1) {
          categorySuggestion = {
            parentTitle: category.title,
            title: category.children[0].title,
            link: {category: categoryIndex, content: 0}
          };
        }

        // Ищем среди страниц
        for (let pageIndex = 0; pageIndex < category.children.length; pageIndex++) {
          if (res.length >= max) {
            break;
          }

          const page = category.children[pageIndex];

          if (page.title.toLowerCase().search(query) != -1) {
            res.push({
              parentTitle: category.title,
              title: page.title,
              link: {category: categoryIndex, content: pageIndex}
            });

            if (
                !!categorySuggestion
                && categoryIndex === categorySuggestion.link.category
                && pageIndex === categorySuggestion.link.content
            ) {
              categorySuggestion = null;
            }
          }

          // Ищем среди заголовков страниц
          if (!!page.children && page.children.length > 0) {
            for (let headerIndex = 0; headerIndex < page.children.length; headerIndex++) {
              if (res.length >= max) {
                break;
              }

              const header = page.children[headerIndex];

              if (header.title.toLowerCase().search(query) != -1) {
                res.push({
                  parentTitle: category.title,
                  title: header.title,
                  link: {category: categoryIndex, content: pageIndex, link: header.title}
                });

                if (!!categorySuggestion
                    && categoryIndex === categorySuggestion.link.category
                    && pageIndex === categorySuggestion.link.content
                ) {
                  categorySuggestion = null;
                }
              }
            }
          }
        }

        if (!!categorySuggestion) {
          res.push(categorySuggestion);
        }
      }

      return res;
    },

    // make suggestions align right when there are not enough items
    alignRight() {
      const navCount = ([]).length
      const repo = 0
      return navCount + repo <= 2
    }
  },
  methods: {
    onUp() {
      if (this.showSuggestions) {
        if (this.focusIndex > 0) {
          this.focusIndex--
        } else {
          this.focusIndex = this.suggestions.length - 1
        }
      }
    },
    onDown() {
      if (this.showSuggestions) {
        if (this.focusIndex < this.suggestions.length - 1) {
          this.focusIndex++
        } else {
          this.focusIndex = 0
        }
      }
    },
    go(i) {
      if (!this.showSuggestions) {
        return
      }
      this.$emit('change-content', this.suggestions[i].link)
      this.query = ''
      this.focusIndex = 0
    },
    focus(i) {
      this.focusIndex = i
    },
    unfocus() {
      this.focusIndex = -1
    },
  }
}
</script>

<style scoped lang="scss">
.search-box {
  width: 90%;
  position: relative;
  margin-right: 1rem;
  margin-bottom: 20px;
  display: inline-block;

  input {
    cursor: text;
    width: 90%;
    height: 2rem;
    color: #4e6e8e;
    display: inline-block;
    border: 1px solid #cfd4db;
    border-radius: 2rem;
    font-size: 0.9rem;
    line-height: 2rem;
    padding: 0 0.5rem 0 2rem;
    outline: none;
    transition: all 0.2s ease;
    background: #fff url("search.svg") 0.6rem 0.5rem no-repeat;
    background-size: 1rem;

    &:focus {
      cursor: auto;
      border-color: #82568e;
    }

    &:focus {
      cursor: text;
      left: 0;
    }
  }

  .suggestions {
    z-index: 10;
    background: #fff;
    width: 100%;
    position: absolute;
    top: 2rem;
    border: 1px solid #cfd4db;
    border-radius: 6px;
    padding: 0.4rem;
    list-style-type: none;

    &.align-right {
      right: 0;
    }
  }

  .suggestion {
    line-height: 1.4;
    padding: 0.4rem 0.6rem;
    border-radius: 4px;
    cursor: pointer;

    a {
      white-space: normal;
      color: #5d82a6;

      .page-title {
        font-weight: 600;
      }

      .page-part {
        font-size: 0.9em;
        margin-left: 0.25em;
      }
    }

    &.focused {
      background-color: #f3f4f5;

      a {
        color: #82568e;
      }
    }
  }

  input {
    left: 1rem;
  }

  .suggestions {
    right: 0;
  }
}

//media (max-width: 959px) and (min-width: 719px) {
//  cursor: pointer;
//  width: 0;
//  border-color: transparent;
//  position: relative;
//  left: 0;
//}
//media all and (-ms-high-contrast: none) {
//  height: 2rem;
//}
//
//media (max-width: 719px) {
//  margin-right: 0;
//}
//
//media (max-width: 419px) {
//  width: calc(100vw - 4rem);
//}

</style>

<i18n>
{
    "en": {
        "defaultPlaceholder": "Start typing..."
    },
    "ru": {
        "defaultPlaceholder": "Начните вводить..."
    }
}
</i18n>
