/* eslint-disable */

// Get Started section
import Introduction from './GetStarted/index.md';
import SystemRequirements from './GetStarted/SystemRequirements.md';
import CreateAccount from './GetStarted/CreateAccount.md';

// Account section
import AccountOverview from './account/README.md';
import CreatePresentation from './account/projects.md';
import ProfileSetting from './account/profile-settings.md';
import LogOut from './account/log-out.md';

// Editor section
import EditorOverview from './editor/README.md';
import EditorInterface from './editor/interface.md';
import EditorSlideArea from './editor/slide-area.md';
import EditorObjectsManipulations from './editor/objects-manipulations.md';
import EditorFeatures from './editor/features.md';
import EditorTables from './editor/tables.md';
import EditorSlidesPanel from './editor/slides-panel.md';
import EditorToolbar from './editor/toolbar.md';
import EditorServiceBar from './editor/service-bar.md';
import EditorRightPanel from './editor/right-panel.md';
import EditorPropertyTypes from './editor/property-types.md';
import EditorGroupsOfProperties from './editor/groups-of-properties.md';

// Viewer section
import ViewerOverview from './viewer/README.md';
import PresentationViewer from './viewer/interface.md';
import PresentationControls from './viewer/presentation-controls.md';
import SharingPresentation from './viewer/sharing-presentations.md';

// How to section
import HowToIntro from './howto/README.md';
import HowToDesignTitle from './howto/how-to-make-a-great-title-cover-slide.md';
import HowToUseGradient from './howto/how-to-use-gradients-in-presentations.md';
import HowToChooseImage from './howto/choose-image.md';

export const data = [
    {
        title: 'Начать',
        url: 'get-started',
        children: [
            {
                title: 'Введение',
                url: 'introduction',
                content: Introduction
            },
            {
                title: 'Системные требования',
                url: 'system-requirements',
                content: SystemRequirements,
                children: [
                    {title: 'Веб-приложение'},
                    {title: 'Приложение'},
                ]
            },
            {
                title: 'Создание аккаунта',
                url: 'create-account',
                content: CreateAccount,
                children: [
                    {title: 'Регистрация через Google'},
                    {title: 'Регистрация через Facebook'},
                    {title: 'Регистрация через Email'},
                    {title: 'Забыли пароль?'},
                ]
            }
        ],
    },
    {
        title: 'Аккаунт',
        url: 'account',
        children: [
            {
                url: 'account-overview',
                title: 'Обзор',
                content: AccountOverview
            },
            {
                url: 'create-presentation',
                title: 'Создание презентации',
                content: CreatePresentation
            },
            {
                url: 'profile-setting',
                title: 'Настройки профиля',
                content: ProfileSetting,
                children: [
                    {title: 'Изменение имени или email'},
                    {title: 'Изменение пароля'},
                ]
            },
            {
                url: 'log-out',
                title: 'Выйти',
                content: LogOut
            },
        ],
    },
    {
        title: 'Редактор',
        url: 'editor',
        children: [
            {
                url: 'editor-overview',
                title: 'Обзор',
                content: EditorOverview
            },
            {
                url: 'editor-interface',
                title: 'Интерфейс',
                content: EditorInterface
            },
            {
                url: 'editor-slide-area',
                title: 'Область слайдов',
                content: EditorSlideArea,
                children: [
                    {title: 'Границы слайда'},
                    {title: 'Масштабирование и прокрутка слайда'},
                    {title: 'Линейки и направляющие'},
                ]
            },
            {
                url: 'editor-objects-manipulations',
                title: 'Манипуляции с объектами',
                content: EditorObjectsManipulations,
                children: [
                    {title: 'Добавление объектов'},
                    {title: 'Добавление таблиц'},
                    {title: 'Добавление изображений'},
                    {title: 'Добавление изображений из панели инструментов'},
                    {title: 'Добавление изображений путем перетаскивания файла'},
                    {title: 'Выбрать объект'},
                    {title: 'Редактировать объекты'},
                    {title: 'Копировать, вырезать и вставлять объекты'},
                ]
            },
            {
                url: 'editor-features',
                title: 'Возможности редактора презентаций',
                content: EditorFeatures,
                children: [
                    {title: 'Редактирование текста'},
                    {title: 'Контекстное меню'},
                    {title: 'Копирование и применение стилей объекта'},
                    {title: 'Клавиши-модификаторы'},
                ]
            },
            {
                url: 'editor-tables',
                title: 'Таблицы',
                content: EditorTables,
                children: [
                    {title: 'Редактирование таблицы'},
                    {title: 'Обработчики таблиц и ограничения'},
                ]
            },
            {
                url: 'editor-slides-panel',
                title: 'Панель слайдов',
                content: EditorSlidesPanel
            },
            {
                url: 'editor-toolbar',
                title: 'Панель инструментов',
                content: EditorToolbar
            },
            {
                url: 'editor-service-bar',
                title: 'Сервисная панель',
                content: EditorServiceBar
            },
            {
                url: 'editor-right-panel',
                title: 'Правая панель',
                content: EditorRightPanel,
                children: [
                    {title: 'Свойства слайда'},
                    {title: 'Свойства выбранного объекта (или группы объектов)'},
                    {title: 'Свойства перехода между слайдами'},
                    {title: 'Медиа'},
                    {title: 'Мои медиа'},
                    {title: 'Вкладка "Внешние ресурсы"'},
                    {title: 'Вкладка "Корзина"'},
                    {title: 'Настройки проекта'},
                ]
            },
            {
                url: 'editor-property-types',
                title: 'Типы свойств',
                content: EditorPropertyTypes,
                children: [
                    {title: 'Число'},
                    {title: 'Цвет'},
                    {title: 'Градиент'},
                    {title: 'Изображение'},
                    {title: 'Режим обложки'},
                    {title: 'Режим растяжения'},
                    {title: 'Режим плитки'},
                    {title: 'Пользовательский режим'},
                ]
            },
            {
                url: 'editor-groups-of-properties',
                title: 'Свойства групп',
                content: EditorGroupsOfProperties,
                children: [
                    {title: 'Выравнивание'},
                    {title: 'Позиция'},
                    {title: 'Вид'},
                    {title: 'Фигура'},
                    {title: 'Заливка'},
                    {title: 'Обводка'},
                    {title: 'Стрелки'},
                    {title: 'Текст'},
                    {title: 'Анимация'},
                ]
            },
        ],
    },
    {
        title: 'Просмотр',
        url: 'viewer',
        children: [
            {
                url: 'viewer-overview',
                title: 'Обзор',
                content: ViewerOverview
            },
            {
                url: 'presentation-viewer',
                title: 'Интерфейс просмотра презентаций',
                content: PresentationViewer
            },
            {
                url: 'presentation-controls',
                title: 'Элементы управления презентацией',
                content: PresentationControls
            },
            {
                url: 'sharing-presentation',
                title: 'Поделиться презентацией',
                content: SharingPresentation
            },
        ],
    },
    {
        title: 'Доп. информация',
        url: 'how-to',
        children: [
            {
                url: 'how-to-intro',
                title: 'Введение',
                content: HowToIntro
            },
            {
                url: 'how-to-design-title',
                title: 'Как оформить титульные слайды',
                content: HowToDesignTitle
            },
            {
                url: 'how-to-use-gradient',
                title: 'Как использовать градиенты',
                content: HowToUseGradient
            },
            {
                url: 'how-to-choose-image',
                title: 'Как выбирать изображения',
                content: HowToChooseImage
            },
        ],
    },
]
