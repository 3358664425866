// Get Started section
/* eslint-disable */
import Introduction from './GetStarted/index.md';
import SystemRequirements from './GetStarted/SystemRequirements.md';
import CreateAccount from './GetStarted/CreateAccount.md';

// Account section
import AccountOverview from './account/README.md';
import CreatePresentation from './account/projects.md';
import ProfileSetting from './account/profile-settings.md';
import LogOut from './account/log-out.md';

// Editor section
import EditorOverview from './editor/README.md';
import EditorInterface from './editor/interface.md';
import EditorSlideArea from './editor/slide-area.md';
import EditorObjectsManipulations from './editor/objects-manipulations.md';
import EditorFeatures from './editor/features.md';
import EditorTables from './editor/tables.md';
import EditorSlidesPanel from './editor/slides-panel.md';
import EditorToolbar from './editor/toolbar.md';
import EditorServiceBar from './editor/service-bar.md';
import EditorRightPanel from './editor/right-panel.md';
import EditorPropertyTypes from './editor/property-types.md';
import EditorGroupsOfProperties from './editor/groups-of-properties.md';

// Viewer section
import ViewerOverview from './viewer/README.md';
import PresentationViewer from './viewer/interface.md';
import PresentationControls from './viewer/presentation-controls.md';
import SharingPresentation from './viewer/sharing-presentations.md';

// How to section
import HowToIntro from './howto/README.md';
import HowToDesignTitle from './howto/how-to-make-a-great-title-cover-slide.md';
import HowToUseGradient from './howto/how-to-use-gradients-in-presentations.md';
import HowToChooseImage from './howto/choose-image.md';

export const data = [
    {
        url: 'get-started',
        title: 'Get Started',
        children: [
            {
                url: 'introduction',
                title: 'Introduction',
                content: Introduction
            },
            {
                url: 'system-requirements',
                title: 'System requirements',
                content: SystemRequirements,
                children: [
                    {title: 'Web App'},
                    {title: 'Desktop App'},
                ]
            },

            {
                url: 'create-account',
                title: 'Create an account',
                content: CreateAccount,
                children: [
                    {title: 'Sign up via Google account'},
                    {title: 'Sign up via Facebook account'},
                    {title: 'Sign up via Email Address'},
                    {title: 'Forgot password?'},
                ]
            },
        ],
    },
    {
        url: 'account',
        title: 'Account',
        children: [
            {
                url: 'account-overview',
                title: 'Overview',
                content: AccountOverview
            },
            {
                url: 'create-presentation',
                title: 'Create presentation project',
                content: CreatePresentation
            },
            {
                url: 'profile-setting',
                title: 'Profile settings',
                content: ProfileSetting,
                children: [
                    {title: 'Change your name or email'},
                    {title: 'Change password'},
                ]
            },
            {
                url: 'log-out',
                title: 'Log out',
                content: LogOut
            },
        ],
    },
    {
        url: 'editor',
        title: 'Editor',
        children: [
            {
                url: 'editor-overview',
                title: 'Overview',
                content: EditorOverview
            },
            {
                url: 'editor-interface',
                title: 'Interface',
                content: EditorInterface
            },
            {
                url: 'editor-slide-area',
                title: 'Slide area',
                content: EditorSlideArea,
                children: [
                    {title: 'Slide boundaries'},
                    {title: 'Scaling and scrolling slide'},
                    {title: 'Rulers and Guides'},
                ]
            },
            {
                url: 'editor-objects-manipulations',
                title: 'Objects\' manipulations',
                content: EditorObjectsManipulations,
                children: [
                    {title: 'Adding objects'},
                    {title: 'Adding tables'},
                    {title: 'Adding images'},
                    {title: 'Adding images from the toolbar'},
                    {title: 'Adding images by dropping a file'},
                    {title: 'Select object'},
                    {title: 'Edit objects'},
                    {title: 'Copy, cut and paste objects'},
                ]
            },
            {
                url: 'editor-features',
                title: 'Presentation editor features',
                content: EditorFeatures,
                children: [
                    {title: 'Text editing'},
                    {title: 'Context menu'},
                    {title: 'Copy and apply object style'},
                    {title: 'Key modifiers'},
                ]
            },
            {
                url: 'editor-tables',
                title: 'Tables',
                content: EditorTables,
                children: [
                    {title: 'Table editing'},
                    {title: 'Table handlers and restrictions'},
                ]
            },
            {
                url: 'editor-slides-panel',
                title: 'Slides panel',
                content: EditorSlidesPanel
            },
            {
                url: 'editor-toolbar',
                title: 'Toolbar',
                content: EditorToolbar
            },
            {
                url: 'editor-service-bar',
                title: 'Service bar',
                content: EditorServiceBar
            },
            {
                url: 'editor-right-panel',
                title: 'Right panel',
                content: EditorRightPanel,
                children: [
                    {title: 'Slide properties'},
                    {title: 'Selection properties'},
                    {title: 'Slides transition properties'},
                    {title: 'Media library'},
                    {title: 'My media tab'},
                    {title: 'External resources tab'},
                    {title: 'Trash tab'},
                    {title: 'Project settings'},
                ]
            },
            {
                url: 'editor-property-types',
                title: 'Property types',
                content: EditorPropertyTypes,
                children: [
                    {title: 'Number'},
                    {title: 'Color'},
                    {title: 'Gradient'},
                    {title: 'Image'},
                    {title: 'Cover mode'},
                    {title: 'Stretch mode'},
                    {title: 'Tile mode'},
                    {title: 'Custom mode'},
                ]
            },
            {
                url: 'editor-groups-of-properties',
                title: 'Groups of properties',
                content: EditorGroupsOfProperties,
                children: [
                    {title: 'Align'},
                    {title: 'Position'},
                    {title: 'Appearance'},
                    {title: 'Shape'},
                    {title: 'Fill'},
                    {title: 'Stroke'},
                    {title: 'Arrows'},
                    {title: 'Text'},
                ]
            },
        ],
    },
    {
        url: 'viewer',
        title: 'Viewer',
        children: [
            {
                url: 'viewer-overview',
                title: 'Overview',
                content: ViewerOverview
            },
            {
                url: 'presentation-viewer',
                title: 'Presentation viewer interface',
                content: PresentationViewer
            },
            {
                url: 'presentation-controls',
                title: 'Presentation controls',
                content: PresentationControls
            },
            {
                url: 'sharing-presentation',
                title: 'Sharing presentation',
                content: SharingPresentation
            },
        ],
    },
    {
        url: 'how-to',
        title: 'How to',
        children: [
            {
                url: 'how-to-intro',
                title: 'Introduction',
                content: HowToIntro
            },
            {
                url: 'how-to-design-title',
                title: 'How to design title slides',
                content: HowToDesignTitle
            },
            {
                url: 'how-to-use-gradient',
                title: 'How to use gradients',
                content: HowToUseGradient
            },
            {
                url: 'how-to-choose-image',
                title: 'How to choose images',
                content: HowToChooseImage
            },
        ],
    },
]
