<template>
<div class="docs-navigation">
  <div>
    <a @click="goTo(prevLink)" v-show="!!prevLink.title" class="nav-link">
      <span>←</span> {{prevLink.title}}
    </a>
  </div>

  <div>
    <a @click="goTo(nextLink)" v-show="!!nextLink.title" class="nav-link">
      {{nextLink.title}} <span>→</span>
    </a>
  </div>
</div>
</template>

<script>
/* eslint-disable */
export default {
  name: "DocsNavigation",
  props: {
    data: Array,
    content: Object,
  },
  methods: {
    goTo(link) {
      this.$emit('change-content', {
        category: link.category,
        content: link.content,
        link: null
      })
    }
  },
  computed: {
    prevLink() {
      const data = this.data;
      const {category, content} = this.content;

      if(category === 0 && content === 0) {
        return {};
      }

      if(content === 0) {
        const currentContent = data[category - 1].children.length - 1;

        return {
          title: data[category - 1].children[currentContent].title,
          category: category - 1,
          content: currentContent
        };
      } else {
        return {
          title: data[category].children[content -1].title,
          category,
          content: content - 1
        };
      }
    },
    nextLink() {
      const data = this.data;
      const {category, content} = this.content;
      const lastCategory = data.length - 1;
      const lastCategoryLastIndex = data[lastCategory].children.length - 1;

      if(category === lastCategory && content === lastCategoryLastIndex) {
        return {};
      }

      const currentCategoryLastIndex = data[category].children.length - 1;

      if(content === currentCategoryLastIndex) {
        return {
          title: data[category + 1].children[0].title,
          category: category + 1,
          content: 0
        };
      } else {
        return {
          title: data[category].children[content + 1].title,
          category: category,
          content: content + 1
        };
      }
    },
  }
}
</script>

<style scoped lang="scss">
.docs-navigation {
  width: 100%;
  display: flex;
  max-width: 740px;
  margin: 0 auto 35px;
  padding: 18px 0 0 0;
  border-top: 1px solid #eaecef;
  justify-content: space-between;

  .nav-link {
    color: #82568e;
    cursor: pointer;
    font-weight: 500;
    user-select: none;
    text-decoration: none;

    span {
      color: #2d2d2d;
    }
  }
}
</style>
